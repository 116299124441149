import { useTranslation } from "react-i18next";
import { Col, Row, Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const CartSkeletonPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="wizard-progress wizard-progress-lg"
        data-testid="pasos-compra-skeleton"
      >
        <div className="steps-progress">
          <div className="progress-indicator"></div>
        </div>
        <ul className="nav wizard-steps">
          <li className="nav-item active">
            {/* eslint-disable-next-line */}
            <a>
              <span>1</span>
              {t("total_carrito_de_compra")}
            </a>
          </li>

          <li className="nav-item">
            {/* eslint-disable-next-line */}
            <a>
              <span>1</span>
              {t("envio_y_entrega")}
            </a>
          </li>
        </ul>
      </div>
      <Row>
        <Col lg={12} xl={8}>
          <div id="components-cart-step1-products">
            <h3>
              <Skeleton width={500} />
            </h3>
            <p>
              <Skeleton count={1} width={400} />
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Skeleton width={200} height={40} />
            </div>
            <h2>
              <Skeleton />
            </h2>
            <section className="d-flex flex-column justify-content-start align-items-start h-100">
              <Table className="table-no-more">
                <thead className="text-brand-gray-dark">
                  <tr>
                    <th>{t("articulo_one")}</th>
                    <th>{""}</th>
                    <th>{t("neto")}</th>
                    <th>{t("cantidad")}</th>
                    <th>{t("subtotal") + " " + t("neto")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Skeleton width={60} height={60} />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton width={60} height={60} />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton width={60} height={60} />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton width={60} height={60} />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </section>

            <section className="d-flex justify-content-end mb-4">
              <div className="total p-2 text-white">
                <span className="uppercase mr-4">
                  <Skeleton />
                </span>{" "}
                <span className="fw-bold">
                  <Skeleton />
                </span>
              </div>
            </section>
          </div>
        </Col>
        <Col lg={12} xl={4}>
          <div id="sidebar" className="border p-4">
            <h5 className="uppercase fw-bold mb-4">
              {t("total_carrito_de_compra")}
            </h5>

            <section>
              <Row className="mb-3">
                <div className="d-flex justify-content-between border-bottom pb-3">
                  <Col>
                    <div>{t("articulo_one")}</div>
                  </Col>
                  <Col className="text-center">
                    <div>{t("cantidad")}</div>
                  </Col>
                  <Col className="text-right">
                    <div>{t("precio")}</div>
                  </Col>
                </div>
                <div className="d-flex justify-content-between border-bottom py-2">
                  <Col>
                    <Skeleton width={80} />
                  </Col>
                  <Col>
                    <Skeleton />
                  </Col>
                  <Col className="text-right">
                    <Skeleton width={80} />
                  </Col>
                </div>
              </Row>
            </section>

            <section className="d-flex justify-content-between pb-3 border-bottom">
              <div>
                <span className="fw-bold text-brand-gray-dark">
                  {t("subtotal")}
                </span>
              </div>
              <div>
                <span className="fw-bold h5">
                  <Skeleton width={80} />
                </span>
              </div>
            </section>

            <section className="d-flex">
              <Skeleton className="mt-3" width={80} height={40} />
            </section>
          </div>
        </Col>
      </Row>
    </>
  );
};
