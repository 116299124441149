import { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronRight, BiSearch } from "react-icons/bi";
import { CgPlayListSearch } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Button, Form, InputGroup } from "react-bootstrap";

import { ROUTES, SEARCH_RESULT_TYPES } from "../../global";
import { getSuggestions } from "../../api/search/getSuggestions";
import {
  getHrefFromSearchEndpointResult,
  getSearchResultType,
} from "../../helpers";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import "./styles/SearchForm.scss";
import slugify from "slugify";

export const SearchForm = ({ id, css = "" }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [match, setMatch] = useState(undefined);
  const ref = useRef();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const SearchResult = ({ result }) => {
    return (
      <div
        role="button"
        className="text-brand-gray-dark text-2"
        onClick={() => handleChange([result])}
        title={result._source.path}
        data-testid="searcher-search-result"
      >
        <BiChevronRight size="1.25rem" />
        {[SEARCH_RESULT_TYPES.content, SEARCH_RESULT_TYPES.product].includes(
          getSearchResultType(result._source),
        ) ? (
          <span className="fw-bold">{result._source.nombre}</span>
        ) : (
          <Fragment>
            {typeof result._source.path === "string"
              ? result._source.path.split("/").map((part, i, parts) => {
                  return i + 1 === parts.length ? (
                    <span key={`${part}-${i}`} className="fw-bold">
                      {part}
                    </span>
                  ) : (
                    <span key={`${part}-${i}`} className="text-brand-gray">
                      {part}/
                    </span>
                  );
                })
              : ""}
          </Fragment>
        )}
      </div>
    );
  };

  /**
   * @description Se salta el filtrado en el lado del cliente ya que los resultados vienen filtrados de la API.
   * @returns {true}
   */
  const filterBy = () => true;

  const gotoResult = (selectedOption) => {
    getSearchResultType(selectedOption._source) === SEARCH_RESULT_TYPES.content
      ? window.open(
          `${process.env.REACT_APP_WP_URL}/${selectedOption._source.slug}`,
          "_blank",
        )
      : navigate(
          getHrefFromSearchEndpointResult(
            selectedOption,
            i18n.resolvedLanguage,
          ),
        );
  };

  const handleSearch = (query) => {
    // Las consultas al endpoint /search deben tener al menos 4 caracteres o no devuelven espuesta desde el back.
    if (!query || query.length < 4) {
      setOptions([]);
      setMatch(undefined);

      return;
    }

    setIsLoading(true);

    getSuggestions(i18n.resolvedLanguage, query).then((response) => {
      setOptions(response.hits?.hits ?? []);
      setMatch(
        response.hits?.hits?.find((hit) =>
          [
            slugify(hit._source.nombre, { lower: true }),
            slugify(hit._source.nombre2, { lower: true }),
          ].includes(slugify(query, { lower: true })),
        ),
      );
      setIsLoading(false);
    });
  };

  const handleChange = (selected) => {
    if (selected.length === 0) {
      return;
    }

    ref.current?.clear();

    gotoResult(selected[0]);
  };

  /**
   * @description En este evento se controla si se ha seleccionado una opción o no, ya que como primero se dispara éste
   * y luego el `change` pueden dar problemas.
   * - Si se ha seleccionado una opción, es decir, si el valor del input
   * corresponde a alguno de las opciones disponibles, entonces este evento retorna sin hacer nada y deja que se
   * encargue el evento `change`.
   * - Si no se ha seleccionado una opción entonces al pulsar intro se debe ir a la pantalla de resultado de búsquedas.
   * @see handleChange
   * @param {Event} event Evento disparado.
   * @returns {void}
   */
  const handleKeyDown = (event) => {
    // ¿Es la tecla Intro?
    if (event.keyCode !== 13) {
      return;
    }

    // ¿Tiene valor el input?
    if (!event.currentTarget?.value || event.currentTarget?.value === "") {
      return;
    }

    // ¿Corresponde con alguna opción existente?
    if (match) {
      ref.current?.clear();
      gotoResult(match);
      setMatch(undefined);

      return;
    }

    handleGoToSearch(event.currentTarget.value);
  };

  const handleGoToSearch = (search = null) => {
    const html = document.querySelector("html");
    html.classList.remove("sidebar-left-collapsed");
    html.classList.toggle("sidebar-left-opened");

    const q = search ?? ref.current.inputNode.value;
    navigate(`/${ROUTES[i18n.resolvedLanguage].search}?q=${encodeURI(q)}`);
    clearInputs();
  };

  const clearInputs = () => {
    ref.current.clear();
    setMatch(undefined);
  };

  return (
    <Form.Group
      className={`search nav-form components-ui-search-form ${css}`.trim()}
    >
      <InputGroup>
        <AsyncTypeahead
          filterBy={filterBy}
          id={id}
          isLoading={isLoading}
          labelKey={(item) => item._source.nombre}
          minLength={2}
          onSearch={handleSearch}
          options={options}
          placeholder={`${t("buscar")}...`}
          searchText={`${t("buscando_resultados")}...`}
          ref={ref}
          useCache={false}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          renderMenuItemChildren={(item) => (
            <SearchResult result={item} locale={i18n.resolvedLanguage} />
          )}
        >
          {match &&
            getSearchResultType(match._source) !==
              SEARCH_RESULT_TYPES.content && (
              <Link
                to={getHrefFromSearchEndpointResult(
                  match,
                  i18n.resolvedLanguage,
                )}
                onClick={clearInputs}
                className="btn btn-default btn-xs btn-goto"
              >
                {match._source.tipo_documento.toLowerCase() === "producto" ? (
                  t("ir_al_producto")
                ) : (
                  <Fragment>
                    <CgPlayListSearch size="1rem" />{" "}
                    {t("ir_a_X", { name: match._source.nombre })}
                  </Fragment>
                )}
              </Link>
            )}
        </AsyncTypeahead>
        <Button
          onClick={handleGoToSearch}
          variant="default"
          data-testid="searcher-search-button"
        >
          <span className="visually-hidden">{t("buscar")}</span>
          <BiSearch size="1.25rem" />
        </Button>
      </InputGroup>
    </Form.Group>
  );
};
